import * as React from "react"
import { default as PaginationMui } from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"
import Select from "components/Inputs/Select/Select"
import { Grid } from "@mui/material"

const Pagination = ({
  page,
  onChange,
  perStep,
  setPerStep,
  count,
  hideSelect,
  ...rest
}) => {
  const selectOptions = [
    {
      label: "10 ofert",
      value: 10,
    },
    {
      label: "15 ofert",
      value: 15,
    },
    {
      label: "20 ofert",
      value: 20,
    },
  ]

  const emptyOnChange = () => {}

  if (!count) {
    return null
  }
  return (
    <Grid container justifyContent={hideSelect ? "center" : "space-between"}>
      {!hideSelect && (
        <Grid item>
          <Select
            selectOptions={selectOptions}
            defaultValue={perStep || 10}
            maxWidth={150}
            onChange={(e, val) => {
              setPerStep(val.props.value)
            }}
          />
        </Grid>
      )}

      <Grid item>
        <Stack spacing={2}>
          <PaginationMui
            count={count}
            page={page || 1}
            onChange={onChange || emptyOnChange}
            color="primary"
          />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default Pagination
