import { FormControl } from "@mui/material"
import styled from "styled-components"
import { theme } from "theme/theme"

export const StyledInput = styled(FormControl)`
  && {
    .MuiInputLabel-root {
      color: #9eb0b3;
      font-weight: 400;
      transform: translate(14px, 8px) scale(1);
    }
    .MuiFormLabel-root.Mui-focused {
      transform: translate(14px, -9px) scale(0.75);
      color: ${theme.palette.primary.main};
      font-weight: 900;
    }
    .MuiFormLabel-root.MuiFormLabel-filled {
      transform: translate(14px, -9px) scale(0.75);
      color: ${theme.palette.primary.main};
      font-weight: 900;
    }

    .MuiMenuItem-root {
      min-height: 39px;
    }
  }
`
