import React from "react"
import Layout from "hoc/Layout"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import BlogCards from "containers/Blog/BlogCards/BlogCards"

const Blog = () => {
  const SITE_NAME = "Blog"
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    SITE_NAME,
  ]
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={SITE_NAME}>
      <Hr mb={4} mt={12} />
      <Font42 mb={4}>{SITE_NAME}</Font42>
      <BlogCards />
    </Layout>
  )
}

export default Blog
