import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import BlogPostCardLarge from "../BlogPostCardLarge/BlogPostCardLarge"
import { default as PaginationComponent } from "components/Pagination/Pagination"

const BlogCards = () => {
  const query = useStaticQuery(graphql`
    query BlogPosts {
      allContentfulPostBloga(sort: { order: DESC, fields: createdAt }) {
        nodes {
          createdAt(formatString: "DD.MM.yyyy")
          title
          contentful_id
          shortDescription
          slug
          author {
            name
            surname
            position
          }
          mainImage {
            gatsbyImageData(
              width: 300
              height: 150
              quality: 90
              backgroundColor: "#e5f5f7"
              placeholder: NONE
            )
          }
        }
      }
    }
  `)

  const posts = query.allContentfulPostBloga.nodes

  const [page, setPage] = useState(1)

  const postsCopy = [...posts]
  const parsedItemsArray = postsCopy.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 5)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return (
    <section>
      <Grid container direction="column" spacing={4}>
        {parsedItemsArray?.[page - 1]?.map(post => (
          <BlogPostCardLarge {...post} key={post.contentful_id} />
        ))}
      </Grid>
      <Grid my={4}>
        <PaginationComponent
          count={parsedItemsArray.length}
          hideSelect
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Grid>
    </section>
  )
}

export default BlogCards
