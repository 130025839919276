import * as React from "react"
import MenuItem from "@mui/material/MenuItem"
import { default as SelectMui } from "@mui/material/Select"
import { InputLabel } from "@mui/material"
import { StyledInput } from "./style"

const Select = ({
  selectOptions,
  placeholder,
  defaultValue,
  maxWidth,
  ...rest
}) => {
  return (
    <StyledInput sx={{ minWidth: maxWidth || 275, maxWidth }}>
      <InputLabel id="demo-simple-select-helper-label">
        {placeholder}
      </InputLabel>
      <SelectMui
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        label={placeholder}
        defaultValue={defaultValue}
        {...rest}
      >
        {selectOptions.map(item => (
          <MenuItem value={item.value} key={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </SelectMui>
    </StyledInput>
  )
}

export default Select
