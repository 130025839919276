import styled from "styled-components"
import { theme } from "theme/theme"

export const Dot = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 10px;
  background-color: ${theme.palette.primary.main};
`
